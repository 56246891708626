import { Box, Button } from "@mui/material";
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Controls,
  DefaultEdgeOptions,
  Edge,
  MiniMap,
  Node,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  ReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSurveys from "../../hooks/useSurveys";
import { useAppSelector } from "../../store/hooks";
import { ISurvey, ISurveyQuestion } from "../../store/data.store";

// const fitViewOptions: FitViewOptions = {
//   padding: 0.2,
// };

function findMissingElements(arr1: string[], arr2: string[]) {
  return arr1.filter((element) => !arr2.includes(element));
}

const defaultEdgeOptions: DefaultEdgeOptions = {
  animated: true,
  style: { strokeWidth: 3, stroke: "black" },
};

const SurveyQuestionsFlowPage = () => {
  const { project, id } = useParams();
  const { updateSurvey, getSurveyById } = useSurveys();

  const survey = useAppSelector((state) =>
    state.data.surveys.find((e) => e.surveyId === id)
  );

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [isFirst, setIsFirst] = useState(true);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect: OnConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  const generateConnections = (surveyData: ISurvey) => {
    const groupExit: Node = {
      id: "exit-survey",
      type: "group",
      position: {
        x: 0,
        y: 200 * surveyData.questions.length,
      },
      data: { label: "" },
      style: { width: 200, height: 50 },
      deletable: false,
      resizing: true,
    };
    const questionExit: Node = {
      id: "exit-survey-1",
      data: { label: "Exit Survey" },
      position: { x: 0, y: 20 },
      style: { background: "red", width: 200 },
      parentId: "exit-survey",
      extent: "parent",
      deletable: false,
      resizing: true,
      draggable: false,
    };
    const initialNodes: Node[] = surveyData.questions.flatMap((item, index) => {
      const group: Node = {
        id: item.questionId,
        type: "group",
        position: { x: 0, y: 200 * index },
        data: { label: item.question },
        style: { width: 800, height: 60 },
        deletable: false,
        resizing: true,
      };

      const question: Node = {
        id: `${item.questionId}-1`,
        data: { label: item.question },
        position: { x: 0, y: 20 },
        parentId: item.questionId,
        extent: "parent",
        draggable: false,
        deletable: false,
        resizing: true,
        style: {
          width: 800,
          fontWeight: "700",
          background: "green",
          color: "white",
        },
      };

      const options: Node[] = item.options.map((option, i) => ({
        id: `${item.questionId}-${i + 2}`,
        data: { label: option },
        position: { x: 200 * i + 10, y: 100 },
        parentId: item.questionId,
        extent: "parent",
        style: { background: "#39AFEA", fontWeight: "600" },
        draggable: false,
        deletable: false,
        resizing: true,
      }));

      return [group, question, ...options];
    });

    initialNodes.push(...[groupExit, questionExit]);

    const initialEdges = surveyData.questions.flatMap((item) =>
      item.options.length === 0
        ? []
        : item.options.map((option, i) => ({
            id: `${item.questionId}-${option}`,
            source: `${item.questionId}-1`,
            target: `${item.questionId}-${i + 2}`,
            animated: false,
            deletable: false,
          }))
    );

    setNodes(initialNodes);
    setEdges(initialEdges);
  };

  const generateMissingConnections = (
    missingQuestions: ISurveyQuestion[],
    total: number
  ) => {
    const initialNodes: Node[] = missingQuestions.flatMap((item, index) => {
      const group: Node = {
        id: item.questionId,
        type: "group",
        position: { x: 0, y: 200 * (total + index) },
        data: { label: item.question },
        style: { width: 800, height: 60 },
        deletable: false,
        resizing: true,
      };

      const question: Node = {
        id: `${item.questionId}-1`,
        data: { label: item.question },
        position: { x: 0, y: 20 },
        parentId: item.questionId,
        extent: "parent",
        draggable: false,
        deletable: false,
        resizing: true,
        style: {
          width: 800,
          fontWeight: "700",
          background: "green",
          color: "white",
        },
      };

      const options: Node[] = item.options.map((option, i) => ({
        id: `${item.questionId}-${i + 2}`,
        data: { label: option },
        position: { x: 200 * i + 10, y: 100 },
        parentId: item.questionId,
        extent: "parent",
        style: { background: "#39AFEA", fontWeight: "600" },
        draggable: false,
        deletable: false,
        resizing: true,
      }));

      return [group, question, ...options];
    });

    const initialEdges = missingQuestions.flatMap((item) =>
      item.options.length === 0
        ? []
        : item.options.map((option, i) => ({
            id: `${item.questionId}-${option}`,
            source: `${item.questionId}-1`,
            target: `${item.questionId}-${i + 2}`,
            animated: false,
            deletable: false,
          }))
    );

    return { initialNodes, initialEdges };
  };

  useEffect(() => {
    if (project && id && !survey) {
      getSurveyById(project, id).then((val) => {
        if (val) {
          if (val.connections) {
            const questionIds = findMissingElements(
              val.questions.map((q) => q.questionId),
              Object.keys(val.questionOrder ?? {})
            );
            const missingQuestions = val.questions
              .map((q) => (questionIds.includes(q.questionId) ? q : null))
              .filter(Boolean);

            const missingConn = generateMissingConnections(
              missingQuestions as ISurveyQuestion[],
              val.questions.length - missingQuestions.length
            );

            setNodes([...val.connections.nodes, ...missingConn.initialNodes]);
            setEdges([...val.connections.edges, ...missingConn.initialEdges]);
          } else {
            generateConnections(val);
          }
        }
      });
    }
  }, [getSurveyById, id, project, survey]);

  useEffect(() => {
    if (survey && isFirst) {
      if (survey.connections?.nodes && survey.connections?.edges) {
        const questionIds = findMissingElements(
          survey.questions.map((q) => q.questionId),
          Object.keys(survey.questionOrder ?? {})
        );
        const missingQuestions = survey.questions
          .map((q) => (questionIds.includes(q.questionId) ? q : null))
          .filter(Boolean);

        const missingConn = generateMissingConnections(
          missingQuestions as ISurveyQuestion[],
          survey.questions.length - missingQuestions.length
        );

        setNodes([...survey.connections.nodes, ...missingConn.initialNodes]);
        setEdges([...survey.connections.edges, ...missingConn.initialEdges]);
      } else {
        generateConnections(survey);
      }
      setIsFirst(false);
    }
  }, [isFirst, survey]);

  return (
    <div style={{ width: "100vw", height: "80vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        // onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        // fitView
        // fitViewOptions={fitViewOptions}
        defaultEdgeOptions={defaultEdgeOptions}
      >
        <Controls />
        <MiniMap />
        <Background gap={12} size={1} />
      </ReactFlow>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            if (project && id) {
              updateSurvey(project, id, { connections: { nodes, edges } });
            }
          }}
        >
          Save
        </Button>
      </Box>
    </div>
  );
};

export default SurveyQuestionsFlowPage;
