import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import { QUESTION_TYPE } from "../utils/constants";

export interface Profile {
  uid: string;
  email: string;
  role: string;
  mobile: string;
  firstName: string;
  lastName: string;
  employmentType: string;
  avatar: string;
  isActive: boolean;
  loggedIn?: boolean;
}

export interface ISurveyQuestion {
  questionId: string;
  question: string;
  options: string[];
  isRequired: "Yes" | "No";
  shuffleOptions: "Yes" | "No";
  type: (typeof QUESTION_TYPE)[number];
  minLength?: number | null;
  maxLength?: number | null;
}

export interface ISurvey {
  surveyId: string;
  title: string;
  description: string;
  startDate: Timestamp;
  endDate: Timestamp;
  locations: string[];
  surveyors: string[];
  questionOrder?: Record<string, any>;
  questions: ISurveyQuestion[];
  connections: {
    nodes: any[];
    edges: any[];
  };
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface IProject {
  projectId: string;
  title: string;
  description: string;
  type: string;
  clientName: string;
  clientMobile: string;
  clientAddress: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

// export interface INumbers {
//   total: number;
//   [key: string]: Record<string, number>;
// }

export interface ISurveyResponse {
  docId?: string;
  updatedAt: Timestamp;
  createdAt: Timestamp;
  answers: Answer[];
  coordinates: Coordinates;
  duration: Duration;
  recording?: string;
}

export interface Answer {
  options: string[];
  questionId: string;
  answer: string;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface Duration {
  endTime: Timestamp;
  startTime: Timestamp;
}

export interface DataState {
  employees: Profile[];
  projects: IProject[];
  surveys: ISurvey[];
  user?: User;
  profile?: Profile;
  surveyResponses: (ISurveyResponse & Profile)[];
}

const initialState: DataState = {
  employees: [],
  projects: [],
  surveys: [],
  surveyResponses: [],
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setEmployees(state, action: PayloadAction<DataState["employees"]>) {
      state.employees = action.payload;
    },
    setUser(state, action: PayloadAction<DataState["user"]>) {
      state.user = action.payload;
    },
    setProfile(state, action: PayloadAction<DataState["profile"]>) {
      state.profile = action.payload;
    },
    setProjects(state, action: PayloadAction<DataState["projects"]>) {
      state.projects = action.payload;
    },
    setSurveys(state, action: PayloadAction<DataState["surveys"]>) {
      state.surveys = action.payload;
    },
    setSurveyResponses(
      state,
      action: PayloadAction<DataState["surveyResponses"]>
    ) {
      state.surveyResponses = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setEmployees,
  setUser,
  setProfile,
  setProjects,
  setSurveys,
  setSurveyResponses,
} = dataSlice.actions;

export default dataSlice.reducer;
