import { DeleteForever, QueueOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import InputField from "../../components/inputField";
import useSurveys from "../../hooks/useSurveys";
import { ISurvey } from "../../store/data.store";
import { useAppSelector } from "../../store/hooks";
import {
  QUESTION_TYPE,
  QUESTION_TYPE_MAP,
  timestampToDateForDatePicker,
} from "../../utils/constants";

function nextQuestionId(strings: string[]) {
  const numbers = strings.map((str) => {
    const match = str.match(/\d+/); // Extract numbers using regex
    return match ? parseInt(match[0], 10) : 0; // Convert the first match to an integer
  });
  const maxNumber = Math.max(...numbers);

  return `q${maxNumber + 1}`;
}

const AddSurveyPage = () => {
  const { project, id } = useParams();
  const { addSurvey, updateSurvey, getSurveyById, getProjectById } =
    useSurveys();
  // const { getEmployees } = useEmployees();

  // const employees = useAppSelector((state) => state.data.employees);
  const survey = useAppSelector((state) =>
    state.data.surveys.find((e) => e.surveyId === id)
  );
  const projectDetails = useAppSelector((state) =>
    state.data.projects.find((e) => e.projectId === project)
  );

  const [surveyId, setSurveyId] = useState(id);

  // const [surveyors, setSurveyors] = useState<Profile[]>(
  //   employees.filter((e) => survey?.surveyors?.includes(e.uid)) ?? []
  // );

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ISurvey>({
    defaultValues: {
      title: survey?.title || "",
      description: survey?.description || "",
      // locations: survey?.locations || [],
      // surveyors: survey?.surveyors || [],
      // startDate: survey?.startDate ?? new Date(),
      // endDate: survey?.endDate ?? new Date(),
      questions: survey?.questions?.map((e) => ({ ...e })) ?? [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });

  // const locations = watch("locations");

  const onSubmit: SubmitHandler<ISurvey> = async (data) => {
    console.log(data);
    if (project) {
      if (id) {
        await updateSurvey(project, id, data);
      } else {
        await addSurvey(project, data).then((val) => setSurveyId(val));
      }
    }
  };
  const disableEdit = false;

  useEffect(() => {
    if (project && id && !survey) {
      getSurveyById(project, id).then((val) => {
        if (val) {
          setValue("title", val?.title || "");
          setValue("description", val?.description || "");
          // setValue("startDate", val?.startDate);
          // setValue("endDate", val?.endDate);
          // setValue("locations", val?.locations || []);
          // setValue("surveyors", val?.surveyors || []);
          setValue("questions", val?.questions?.map((e) => ({ ...e })) ?? []);
          // setSurveyors(
          //   employees.filter((e) => val?.surveyors?.includes(e.uid)) ?? []
          // );
        }
      });
    }
  }, [getSurveyById, id, project, setValue, survey]);

  // useEffect(() => {
  //   getEmployees();
  // }, [getEmployees]);

  useEffect(() => {
    if (!projectDetails && project) getProjectById(project);
  }, [getProjectById, project, projectDetails]);

  return id && !survey ? null : (
    <Box>
      <h2>
        <Link to={`/projects/${project}`}>{projectDetails?.title}</Link> -
        Survey Details
      </h2>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          sx={{ marginBottom: "2rem" }}
        >
          <Grid item xs={12} sm={6}>
            <InputField
              id="title"
              label="Survey Title"
              margin="dense"
              defaultValue={survey?.title ?? ""}
              fullWidth
              {...register("title", { required: true })}
              required
              variant="outlined"
              error={!!errors.title}
              disabled={disableEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              id="description"
              label="Survey Description"
              margin="dense"
              defaultValue={survey?.description ?? ""}
              fullWidth
              {...register("description")}
              variant="outlined"
              disabled={disableEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              id="startDate"
              label="Start Date"
              margin="dense"
              type="date"
              fullWidth
              defaultValue={timestampToDateForDatePicker(survey?.startDate)}
              {...register("startDate", { valueAsDate: true })}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              error={!!errors.startDate}
              disabled={disableEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              id="endDate"
              label="End Date"
              margin="dense"
              type="date"
              fullWidth
              defaultValue={timestampToDateForDatePicker(survey?.endDate)}
              {...register("endDate", { valueAsDate: true })}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              error={!!errors.endDate}
              disabled={disableEdit}
            />
          </Grid>
        </Grid>
        {/* <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2 }}
          sx={{ marginBottom: "2rem" }}
        >
          <Grid item xs={12} sm={6}>
            <h2>Locations:</h2>
            <InputField
              id="locations"
              label="Locations"
              margin="dense"
              fullWidth
              {...register("locations", {
                setValueAs: (v) =>
                  typeof v === "string"
                    ? v.split(",").map((option) => option.trim())
                    : v,
              })}
              variant="outlined"
              error={!!errors.locations}
              placeholder="Comma separated locations"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h2>Surveyors:</h2>
            <Autocomplete
              multiple
              id="surveyors"
              options={employees.filter((e) => e.role === "Associate")}
              value={surveyors}
              disableCloseOnSelect
              getOptionLabel={(option) =>
                `${option.firstName}-${option.mobile}`
              }
              onChange={(_, newValue) => {
                setValue(
                  "surveyors",
                  newValue.map((e) => e.uid)
                );
                setSurveyors(newValue);
              }}
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    <Checkbox checked={selected} />
                    {`${option.firstName}-${option.mobile}`}
                  </li>
                );
              }}
              // style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Surveyors"
                  placeholder="Select Surveyors"
                  size="small"
                  margin="dense"
                />
              )}
            />
          </Grid>
          <Stack direction="row" spacing={1}>
            {locations.map((location) => (
              <Chip
                key={location}
                label={location}
                variant="outlined"
                onDelete={() => {
                  //
                }}
              />
            ))}
          </Stack>
        </Grid> */}

        <Divider />
        <h2>Questions:</h2>
        {fields.map((title, titleIndex) => {
          const isTextField = ["TEXT", "NUMBER"].includes(
            watch(`questions.${titleIndex}.type`)
          );
          return (
            <Box
              key={titleIndex}
              sx={{
                borderBottom: "1px solid grey",
                marginBottom: "1rem",
                paddingBottom: "1rem",
              }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                justifyContent="flex-start"
                alignItems="start"
              >
                {/* Question */}
                <Grid item xs={12} sm={8}>
                  <InputField
                    id={title.id}
                    label={`Question ${titleIndex + 1}`}
                    {...register(`questions.${titleIndex}.question`, {
                      required: true,
                    })}
                    defaultValue={title.question ?? ""}
                    margin="dense"
                    fullWidth
                    disabled={disableEdit}
                    required
                    error={!!errors.questions?.[titleIndex]?.question}
                    variant="outlined"
                  />
                </Grid>
                {/* Question Type */}
                <Grid item xs={11} sm={3}>
                  <InputField
                    id={title.id}
                    label="Question Type"
                    {...register(`questions.${titleIndex}.type`, {
                      required: true,
                    })}
                    defaultValue={title.type || "TEXT"}
                    disabled={disableEdit}
                    margin="dense"
                    fullWidth
                    select
                    required
                    error={!!errors.questions?.[titleIndex]?.question}
                    variant="outlined"
                    onChange={(e) => {
                      const val = e.target
                        .value as (typeof QUESTION_TYPE)[number];
                      if (isTextField) {
                        setValue(`questions.${titleIndex}.options`, []);
                      }
                      setValue(`questions.${titleIndex}.type`, val);
                    }}
                  >
                    {Object.keys(QUESTION_TYPE_MAP).map((key) => (
                      <MenuItem key={key} value={key}>
                        {
                          QUESTION_TYPE_MAP[
                            key as (typeof QUESTION_TYPE)[number]
                          ]
                        }
                      </MenuItem>
                    ))}
                  </InputField>
                </Grid>
                {/* Delete Question */}
                <Grid item xs={1}>
                  {fields.length !== 1 && (
                    <Tooltip title="Delete Question">
                      <span>
                        <IconButton
                          disabled={disableEdit}
                          onClick={() => remove(titleIndex)}
                        >
                          <DeleteForever color="error" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
              {/* Options */}
              {!isTextField && (
                <Grid container rowSpacing={1}>
                  <Grid item xs={12}>
                    <InputField
                      id={title.id}
                      label="Options"
                      {...register(`questions.${titleIndex}.options`, {
                        required: true,
                        setValueAs: (v) =>
                          typeof v === "string"
                            ? v.split(",").map((option) => option.trim())
                            : v,
                      })}
                      disabled={disableEdit}
                      margin="dense"
                      fullWidth
                      required
                      error={!!errors.questions?.[titleIndex]?.options}
                      variant="outlined"
                      placeholder="Comma separated options"
                    />
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {watch(`questions.${titleIndex}.type`) !== "SINGLE" && (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <InputField
                        id={title.id}
                        label="Minimum Count"
                        {...register(`questions.${titleIndex}.minLength`, {
                          valueAsNumber: true,
                          setValueAs: (v) => (!v ? null : v),
                        })}
                        type="number"
                        disabled={disableEdit}
                        margin="dense"
                        fullWidth
                        error={!!errors.questions?.[titleIndex]?.minLength}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <InputField
                        id={title.id}
                        label="Maximum Count"
                        {...register(`questions.${titleIndex}.maxLength`, {
                          valueAsNumber: true,
                          setValueAs: (v) => (!v ? null : v),
                        })}
                        type="number"
                        disabled={disableEdit}
                        margin="dense"
                        fullWidth
                        error={!!errors.questions?.[titleIndex]?.maxLength}
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6} md={3}>
                  <InputField
                    id={title.id}
                    label="Is Required"
                    {...register(`questions.${titleIndex}.isRequired`, {
                      required: true,
                    })}
                    disabled={disableEdit}
                    margin="dense"
                    defaultValue={title.isRequired || "Yes"}
                    fullWidth
                    select
                    required
                    error={!!errors.questions?.[titleIndex]?.isRequired}
                    variant="outlined"
                  >
                    {["Yes", "No"].map((key) => (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </InputField>
                </Grid>
                {!isTextField && (
                  <Grid item xs={12} sm={6} md={3}>
                    <InputField
                      id={title.id}
                      label="Shuffle Options"
                      {...register(`questions.${titleIndex}.shuffleOptions`, {
                        required: true,
                      })}
                      disabled={disableEdit}
                      margin="dense"
                      defaultValue={title.shuffleOptions || "No"}
                      fullWidth
                      select
                      required
                      error={!!errors.questions?.[titleIndex]?.shuffleOptions}
                      variant="outlined"
                    >
                      {["Yes", "No"].map((key) => (
                        <MenuItem key={key} value={key}>
                          {key}
                        </MenuItem>
                      ))}
                    </InputField>
                  </Grid>
                )}
              </Grid>
              {/* Preview Options */}
              <Grid>
                <Grid item xs={6}>
                  <div style={{ fontWeight: "600" }}>Preview: </div>
                  {watch(`questions.${titleIndex}.type`) === "SINGLE" && (
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      {watch(`questions.${titleIndex}.options`).map(
                        (option) => (
                          <FormControlLabel
                            key={option}
                            value={option}
                            control={<Radio />}
                            label={option}
                          />
                        )
                      )}
                    </RadioGroup>
                  )}
                  {watch(`questions.${titleIndex}.type`) === "MULTI" && (
                    <FormGroup row>
                      {watch(`questions.${titleIndex}.options`).map(
                        (option) => (
                          <FormControlLabel
                            key={option}
                            control={<Checkbox />}
                            label={option}
                          />
                        )
                      )}
                    </FormGroup>
                  )}
                </Grid>
              </Grid>
            </Box>
          );
        })}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
            marginBottom: "2rem",
          }}
        >
          <Button
            size="medium"
            variant="outlined"
            type="button"
            startIcon={<QueueOutlined />}
            disabled={disableEdit}
            onClick={() =>
              append({
                questionId: nextQuestionId(fields.map((e) => e.questionId)),
                question: "",
                options: [],
                type: "TEXT",
                isRequired: "Yes",
                shuffleOptions: "No",
                maxLength: null,
              })
            }
          >
            Add Question
          </Button>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
            gap: 5,
          }}
        >
          <Button
            size="large"
            variant="contained"
            type="submit"
            disabled={fields.length === 0}
          >
            Save
          </Button>
          {surveyId && (
            <Link
              to={`/projects/${project}/surveys/${surveyId}/questions-flow`}
            >
              <Button
                size="large"
                variant="outlined"
                disabled={fields.length === 0}
              >
                Setup Questions Flow
              </Button>
            </Link>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default AddSurveyPage;
